import * as React from "react"
import { graphql } from "gatsby"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Person from "./person"
import Hero from "../../components/hero"

const Company = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  return (
    <Layout>
      <Seo title="Company" />
      <section className="container py-10 lg:pb-24">
        <h2 className="py-10 sm:py-16 lg:py-8">About Us</h2>
        <div className="grid lg:grid-cols-1 md:grid-cols-1 gap-5 pb-8">
          {frontmatter.team
            .map(i => (
              <Person key={i.name} person={i} />
            ))}
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        team {
          name
          title
          description
          bio
          profile {
            childImageSharp {
              gatsbyImageData(width: 350, quality: 100, placeholder: BLURRED)
            }
          }
          social {
            linkedin
          }
          isAdvisory
        }
      }
    }
  }
`

export default Company
