import React, { useState, Fragment } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Transition, Dialog } from "@headlessui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import Social from "./social"

export default function Team({ person }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  function closeModal() {
    setIsMenuOpen(false)
  }

  function openModal() {
    setIsMenuOpen(true)
  }

  function bioButton() {
    if (person.bio) {
      return (
        <button
          onClick={() => openModal()}
          type="button"
          className="btn-secondary"
        >
          Bio
        </button>
      )
    }
    return false
  }

  const image = getImage(person.profile)
  return (
    <div className="grid grid-cols-3 gap-4 px-5 py-5 bg-gray-50 rounded hover:shadow-xl group transform transition-all cursor-default">
      <div className="col-span-1">
        <div className="space-y-5">
          <div className="w-full flex items-center justify-center">
            <GatsbyImage
              className={`rounded-full border-purple-600 border-3 object-cover z-10 ${
                person.isAdvisory ? "w-40 h-40 lg:w-32 lg:h-32" : "w-40 h-40"
              }`}
              image={image}
              alt={person.name}
            />
          </div>
          <div className="space-y-3">
            <h3 className="group-hover:text-purple-600 transition-colors">
              {person.name}
            </h3>
            <p className="subtitle font-light uppercase">{person.title}</p>
            <p className="pl-2 border-l border-gray-300">
              {person.description}
            </p>
            <div className="flex flex-1 justify-start space-x-2">
              {Object.entries(person.social)
                .filter(([key, value]) => value)
                .map(([key, value]) => {
                  return (
                    <a
                      key={key}
                      className="text-gray-500 hover:text-gray-900  transition duration-150 ease-in-out"
                      target="_blank"
                      rel="noreferrer"
                      aria-label={key}
                      href={value}
                    >
                      <Social platform={key} />
                    </a>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-span-2 flex justify-between items-end mt-5 flex-1 text-sm"
        dangerouslySetInnerHTML={{ __html: person.bio }}
      ></div>
    </div>
  )
}
