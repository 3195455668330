import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaw } from "@fortawesome/pro-regular-svg-icons"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"

const SocialIcon = ({ platform }) => {
  switch (platform) {
    case "linkedin":
      return <FontAwesomeIcon size="lg" icon={faLinkedin} />
    case "paw":
      return <FontAwesomeIcon size="lg" icon={faPaw} />
    default:
      return false
  }
}

export default SocialIcon
